import app from "firebase/app";
import "firebase/auth";
import "firebase/database";

const config = {
  apiKey: "AIzaSyCf5rI9qFT99zpV50cwzJhEBu-ule8eO3M",
  authDomain: "magic-grand-central-tracking.firebaseapp.com",
  databaseURL:
    "https://magic-grand-central-tracking-default-rtdb.firebaseio.com",
  projectId: "magic-grand-central-tracking",
  storageBucket: "magic-grand-central-tracking.appspot.com",
  messagingSenderId: "438436633807",
  appId: "1:438436633807:web:522fbeabca68b43d896bba",
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.database();
  }

  doSignInAnonymous = () => this.auth.signInAnonymously();

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doForgotPassword = (_email) => this.auth.sendPasswordResetEmail(_email);

  doSignOut = () => this.auth.signOut();

  saveTracking = (trackingObject) => {
    this.db.ref(trackingObject.asset_slug).push({
      ...trackingObject,
      origin_url: window.location.href,
      timestamp: Date.now(),
    });
  };
}

export default Firebase;
