import React, { useContext } from "react";
import { Icon, Menu, Sidebar, Segment } from "semantic-ui-react";
import { Context } from "../libs/redux/Store";

import PdfExplorer from "./Explorer";
import Pdf from "../libs/pdflib";

function Navbar({ assets, flashcards }) {
  const [state, dispatch] = useContext(Context);

  // console.log('the asset url');
  // console.log(state.currentAssetUrl)
  const pdfAssetUrl = state.currentAsset.url;

  return (
    <Sidebar.Pushable as={Segment} className="aside-assets-list">
      <Sidebar
        as={Menu}
        animation="push"
        icon="labeled"
        inverted
        vertical
        visible={true}
        width="thin"
        className="custom-sidebar"
      >
        <div className="assets-list-container">
          {flashcards &&
            flashcards.map((flashcard) => (
              <Menu.Item
                key={flashcard._id}
                as="a"
                onClick={() => {
                  dispatch({
                    type: "SET_CURRENT_ASSET_URL",
                    payload: { url: flashcard?.url, name: flashcard?.label },
                  });
                }}
                className="asset-item"
              >
                <Icon name="file alternate outline" />
                {flashcard.label}
              </Menu.Item>
            ))}
          {assets &&
            assets.map((asset) => (
              <Menu.Item
                key={asset._id}
                as="a"
                onClick={() => {
                  dispatch({
                    type: "SET_CURRENT_ASSET_URL",
                    payload: {
                      url: asset.sanity_pdf?.pdf.asset.url,
                      name: asset.sanity_pdf?.title,
                    },
                  });
                }}
                className="asset-item"
              >
                <Icon name="file alternate outline" />
                {asset.sanity_pdf?.title}
              </Menu.Item>
            ))}
        </div>
      </Sidebar>
      <Sidebar.Pusher>
        {pdfAssetUrl && (
          <PdfExplorer key={pdfAssetUrl} backend={Pdf} src={pdfAssetUrl} />
        )}
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
}

export default Navbar;
