import React, { useEffect, useRef } from "react";

function Explorer({ src, backend }) {
  const viewerRef = useRef(null);
  const backendObj = new backend();

  useEffect(() => {
    const element = viewerRef.current;
    backendObj && backendObj.init(src, element);
  }, []);
  return (
    <div
      ref={viewerRef}
      id="viewer"
      style={{ width: "86vw", height: "100vh" }}
    ></div>
  );
}

export default Explorer;
