export default class Pdf {
  init = (source, element) => {
    const iframe = document.createElement("iframe");

    iframe.src = `/pdfjs-2.8.335-legacy-dist/web/viewer.html?file=${source}`;
    iframe.width = "100%";
    iframe.height = "100%";
    iframe.name = Date.now();

    element.appendChild(iframe);
  };
}
