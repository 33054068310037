import React, { useContext } from "react";
import { Button, Segment, Item, Header } from "semantic-ui-react";
import { Context } from "../libs/redux/Store";

function ListView({ assets, flashcards, setCurrentAsset }) {
  const [state, dispatch] = useContext(Context);
  const currentAsset = state.currentAsset;

  if (!currentAsset) {
    return (
      <Segment className="main-front-assets-list">
        {!currentAsset && (
          <Item.Group relaxed="very">
            {flashcards &&
              flashcards.map((flashcard) => (
                <Item key={flashcard._id}>
                  <Item.Image
                    size="mini"
                    src="/pdf-thumb.png"
                    className="asset-icon"
                  />
                  <Item.Content verticalAlign="middle">
                    <Item.Header
                      as="a"
                      onClick={() => {
                        dispatch({
                          type: "SET_CURRENT_ASSET_URL",
                          payload: {
                            url: flashcard?.url,
                            name: flashcard?.label,
                          },
                        });
                      }}
                    >
                      {flashcard.label}
                    </Item.Header>
                    <Button
                      icon="eye"
                      primary
                      floated="right"
                      content="View"
                      onClick={() => {
                        dispatch({
                          type: "SET_CURRENT_ASSET_URL",
                          payload: {
                            url: flashcard?.url,
                            name: flashcard?.label,
                          },
                        });
                      }}
                    />
                  </Item.Content>
                </Item>
              ))}

            {assets &&
              assets.map((asset) => (
                <Item key={asset._id}>
                  <Item.Image
                    size="mini"
                    src="/pdf-thumb.png"
                    className="asset-icon"
                  />
                  <Item.Content verticalAlign="middle">
                    <Item.Header
                      as="a"
                      onClick={() => {
                        dispatch({
                          type: "SET_CURRENT_ASSET_URL",
                          payload: {
                            url: asset.sanity_pdf?.pdf.asset.url,
                            name: asset.sanity_pdf?.title,
                          },
                        });
                      }}
                    >
                      {asset.sanity_pdf?.title}
                    </Item.Header>
                    <Button
                      icon="eye"
                      primary
                      floated="right"
                      content="View"
                      onClick={() => {
                        dispatch({
                          type: "SET_CURRENT_ASSET_URL",
                          payload: {
                            url: asset.sanity_pdf?.pdf.asset.url,
                            name: asset.sanity_pdf.title,
                          },
                        });
                      }}
                    />
                  </Item.Content>
                </Item>
              ))}
          </Item.Group>
        )}
      </Segment>
    );
  } else {
    return null;
  }
}

export default ListView;
