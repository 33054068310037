import React, { useContext } from "react";
import { Context } from "../libs/redux/Store";

function ErrorPage() {
  const [state, dispatch] = useContext(Context);
  return (
    <div
      style={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        marginTop: "10%",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            border: "5px solid red",
            padding: 30,
            minWidth: 700,
            justifyContent: "center",
          }}
        >
          <p>
            <span style={{ fontWeight: "bold", fontSize: 20 }}>Error: </span>
            {state.error ? (
              <span dangerouslySetInnerHTML={{ __html: state.error }} />
            ) : (
              "Unspecified error occured."
            )}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;
