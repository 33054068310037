import React, { useContext, useEffect, useState } from "react";
import { Container } from "semantic-ui-react";
import { Context } from "../libs/redux/Store";

// const blackboxBrand = "nayzilam";
// //TODO: move this to sanity

function BlackBox({ brand, allPI, flashcards }) {
  const ALL_BRANDS = ["briviact", "nayzilam", "cimzia"];
  const [state, dispatch] = useContext(Context);
  const [brandPI, setBrandPI] = useState(null);
  const currentAsset = state.currentAsset;

  const getPi = (_brandName, _piArray) =>
    _piArray.filter((item) => item.brands.brandName == _brandName);

  useEffect(() => {
    // console.log("brand,", brand);
    // console.log("flashcards", flashcards);
    // console.log("PI", allPI);
    setBrandPI(getPi(brand.brandName, allPI));

    /**I will update here later for multi brand PI */
    //Here we have to update
  }, [brand, allPI]);

  //only show in menu
  if (!currentAsset) {
    return (
      <div>
        {!currentAsset && (
          <Container textAlign="left">
            {brandPI && (
              <div
                dangerouslySetInnerHTML={{
                  __html: brandPI[0]?.piContent?.code,
                }}
              />
            )}
          </Container>
        )}
      </div>
    );
  } else {
    return null;
  }
}

export default BlackBox;
