import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Store from "./libs/redux/Store";
import Firebase, { FirebaseContext } from "./libs/firebase";
import "./index.css";
import App from "./App";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

// Instance of the Firebase object
const firebase = new Firebase();
console.log("process.env.REACT_DATA_API:", process.env.REACT_APP_DATA_API);
const HASURA_API =
  process.env.REACT_APP_DATA_API ||
  "https://mgc-romantic-deer-45.hasura.app/v1/graphql";

const client = new ApolloClient({
  uri: HASURA_API,
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <FirebaseContext.Provider value={{ firebase }}>
        <Store>
          <Router>
            <App />
          </Router>
        </Store>
      </FirebaseContext.Provider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
