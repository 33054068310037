const Reducer = (state, action) => {
  switch (action.type) {
    case "SET_PDF_PARAM": //show/hide left hamburger click
      return {
        ...state,
        pdf_param: action.payload,
      };
    case "SET_ERROR": //show/hide left hamburger click
      return {
        ...state,
        error: action.payload,
      };
    case "SET_CURRENT_ASSET_URL": // SETS Current ASSET URL
      return {
        ...state,
        currentAsset: action.payload,
      };
    default:
      return state;
  }
};

export default Reducer;
