import React, { useContext, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import PageWrapper from "./components/PageWrapper";
import ErrorPage from "./pages/ErrorPage";
import { Context } from "./libs/redux/Store";
import { getUrlParameter } from "./libs/utils";
import "semantic-ui-css/semantic.min.css";
import CollectionPage from "./pages/CollectionPage";
import "./App.css";
const assetParam = getUrlParameter("pdf"); //pdf slug parameter

function App() {
  const [state, dispatch] = useContext(Context);

  useEffect(() => {
    dispatch({
      type: "SET_PDF_PARAM",
      payload: assetParam,
    });
  }, []);

  return (
    <div className="App">
      <PageWrapper>
        <Switch>
          <Route path="/error" component={ErrorPage} />
          <Route path="/*" exact component={CollectionPage} />
        </Switch>
      </PageWrapper>
    </div>
  );
}

export default App;
