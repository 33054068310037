import React, { useState, useContext } from "react";
import { Menu, Button, Header } from "semantic-ui-react";
import { Context } from "../libs/redux/Store";

function TopMenu({
  setMenuVisible,
  menuVisible,
  brand,
  contactModalVisbile,
  setContactModalVisible,
}) {
  const [state, dispatch] = useContext(Context);
  const [activeItem, setActiveItem] = useState("menu");

  const logoURL = brand.brandLogoHeader.asset.url;
  const currentAsset = state.currentAsset;
  //currentAsset && console.log("current asset in menus is,", currentAsset);
  //console.log(currentAsset)
  return (
    <Menu className="header-bar">
      <Menu.Item
        name="menu"
        onClick={() =>
          dispatch({ type: "SET_CURRENT_ASSET_URL", payload: false })
        }
      >
        <Button icon="list" primary content="Menu" disabled={!currentAsset} />
      </Menu.Item>
      <Menu.Item>
        <img style={{ width: "auto", height: "35px" }} src={logoURL} />
      </Menu.Item>
      {currentAsset && (
        <Menu.Item className="asset-title">
          <Header
            as="h1"
            content={currentAsset.name}
            inverted
            style={{
              fontSize: "1.5em",
              fontWeight: "normal",
              color: "black",
            }}
          />
        </Menu.Item>
      )}
    </Menu>
  );
}

export default TopMenu;
