const axios = require("axios").default;

const trackEvent = (evtStr) => {
  let eventObject = null;
  if (evtStr && typeof evtStr.data === "string") {
    eventObject = JSON.parse(evtStr.data);
  }
  return eventObject;
};

// Using cloudflare API to get IP
// We might have to sign up for a service
const fetchIp = async () => {
  try {
    // fetch data from a url endpoint
    const result = await axios.get("https://www.cloudflare.com/cdn-cgi/trace", {
      timeout: 2000,
    });

    let resultStr = "";
    if (result) {
      resultStr = result.data.split("\n").filter((item) => {
        return item.indexOf("ip") !== -1;
      })[0];
      resultStr = resultStr.substr(
        resultStr.indexOf("ip=") + 3,
        resultStr.length
      );
    }

    return resultStr;
  } catch (error) {
    console.log("error", error);
  }
};

const getUrlParameter = (name) => {
  if (typeof window !== "undefined") {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    var results = regex.exec(window.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  }
};

const toTitleCase = (str) => {
  return str.replace(
    /\S+/g,
    (str) => str.charAt(0).toUpperCase() + str.substr(1).toLowerCase()
  );
};

export { trackEvent, fetchIp, getUrlParameter, toTitleCase };
