import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Bowser from "bowser";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Context } from "../libs/redux/Store";
import { trackEvent, fetchIp, getUrlParameter } from "../libs/utils";
import { FirebaseContext } from "../libs/firebase";
import Navbar from "../components/Navbar";
import { gql, useLazyQuery } from "@apollo/client";
import { Loader, Dimmer, Segment, Image } from "semantic-ui-react";
import Menu from "../components/Menu";
import List from "../components/List";
import BlackBox from "../components/BlackBox";

const GET_PAGE = gql`
  query GetPageByID($pageId: uuid) {
    pages(where: { _id: { _eq: $pageId } }) {
      assets {
        _id
        type
        sanity_pdf {
          pdf {
            asset {
              url
              title
            }
          }
          title
        }
      }
      sanity_brand {
        _id
        isActive
        brandColor
        brandName
        brandNotes
        displayWeight
        isActive
        brandLogoHeader {
          _key
          _type
          asset {
            url
          }
        }
      }
      flashcards {
        _id
        url
        label
      }
    }
    allPi {
      _createdAt
      _id
      _key
      _rev
      _type
      _updatedAt
      piInfo
      piTitle
      piContent {
        code
        filename
        _key
      }
      brands {
        brandName
        _id
      }
    }
  }
`;

const isIp = require("is-ip");
const sessionId = uuidv4(); //Unique session id
const browserInfo = Bowser.getParser(window.navigator.userAgent); //Browser Agent Info
const assetParam = getUrlParameter("page"); //pdf slug parameter

function CollectionPage() {
  const [menuVisible, setMenuVisible] = useState(false);

  const [contactModalVisible, setContactModalVisible] = useState(false);

  const [getPage, { loading, data, error }] = useLazyQuery(GET_PAGE);

  const { firebase } = useContext(FirebaseContext);
  const [state, dispatch] = useContext(Context);
  let history = useHistory();

  const onMessageReceivedFromIframe = (event) => {
    const _eventObject = trackEvent(event);
    if (_eventObject) {
      let trackingObject = {
        session_id: sessionId,
        page_id: assetParam.trim().toLowerCase(),
        ip_address: "",
        browser_info: browserInfo,
        event_info: _eventObject,
        asset: state.currentAsset?.url || "",
      };
      fetchIp().then((val) => {
        trackingObject = {
          ...trackingObject,
          ip_address: isIp(val) ? val : "",
        };

        //Push to firebase
        //console.log(trackingObject);
        firebase
          .doSignInAnonymous()
          .then((userCredential) => {
            firebase.saveTracking(trackingObject);
          })
          .catch((error) => {
            console.log("Error", error);
          });
      });
    }
  };

  //IFrame Listener Functions
  const addIframeListener = () =>
    window.addEventListener("message", onMessageReceivedFromIframe);
  const removeIframeListener = () =>
    window.removeEventListener("message", onMessageReceivedFromIframe);

  //Set event listeners
  useEffect(() => {
    addIframeListener();
    return () => {
      removeIframeListener();
    };
  }, [state.currentAsset?.url]);

  useEffect(() => {
    if (assetParam) {
      getPage({
        variables: { pageId: assetParam },
      });
    } else {
      dispatch({
        type: "SET_ERROR",
        payload: "The sadness we have an error... ",
      });
      history.push("/error");
    }
    if (error) {
      dispatch({
        type: "SET_ERROR",
        payload: `"<em>${assetParam}</em>" is invalid PDF name.`,
      });
      history.push("/error");
    }
  }, []);

  // console.log("current asset in collectionPage");
  // console.log(state.currentAsset);
  // const currentAsset = state.currentAsset?.url;

  return (
    <HelmetProvider>
      {data && (
        <>
          <Helmet>
            <title>
              {`${data?.pages[0]?.sanity_brand?.brandName.toUpperCase()} - Content Portal`}
            </title>
          </Helmet>
          <Menu
            setMenuVisible={setMenuVisible}
            menuVisible={menuVisible}
            brand={data.pages[0].sanity_brand}
            contactModalVisible={contactModalVisible}
            setContactModalVisible={setContactModalVisible}
          />
        </>
      )}
      {loading && (
        <Segment>
          <Dimmer active>
            <Loader>Loading</Loader>
          </Dimmer>
          <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
        </Segment>
      )}

      {data && (
        <>
          <List
            assets={data.pages[0].assets}
            flashcards={data.pages[0].flashcards}
          />
          <BlackBox
            brand={data.pages[0].sanity_brand}
            allPI={data.allPi}
            flashcards={data.pages[0].flashcards}
          />
        </>
      )}

      {state.currentAsset?.url && (
        <Navbar
          key={state.currentAsset?.url}
          assets={data.pages[0].assets}
          flashcards={data.pages[0].flashcards}
        />
      )}
    </HelmetProvider>
  );
}

export default CollectionPage;
